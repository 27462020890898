<template>
  <div>
    <div v-if="isLoading && !isInit" class="spinner-border text-info" :style="`width: ${loaderSize}rem; height: ${loaderSize}rem;`" role="status">
      <span class="sr-only">Chargement...</span>
    </div>
    <div v-else class="chart-container" :style="`width: ${chartWidth};height: ${chartHeight};`">
      <div v-if="isLoading">
        <img :style="`width: ${chartWidth};height: ${chartHeight};`" :src="$refs.chart.toDataURL()" />
      </div>
      <div v-else class="chartjs-size-monitor">
        <canvas ref="chart" class="chart" :style="`display: block; width: ${chartWidth};height: ${chartHeight};`"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js'
Chart.register(...registerables)

export default {
  watch: {
    isLoading: {
      handler (_, state) {
        if (state) {
          this.createChart()

          if (!this.isInit) {
            this.isInit = true
          }
        }
      },
      immmediate: true,
      deep: true
    }
  },
  props: {
    isLoading: {
      type: [Boolean, null],
      required: true,
      default: true
    },
    loaderSize: {
      type: [String],
      required: false,
      default: '8'
    },
    type: {
      type: String,
      required: false,
      default: 'doughnut'
    },
    chartWidth: {
      type: String,
      required: false,
      default: '200px'
    },
    chartHeight: {
      type: String,
      required: false,
      default: '200px'
    },
    chartData: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },
    chartOptions: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      chart: null,
      isInit: false
    }
  },
  methods: {
    createChart () {
      this.$nextTick(() => {
        if (this.$refs.chart) {
          const context = this.$refs.chart.getContext('2d')
          context.clearRect(0, 0, this.$refs.chart.width, this.$refs.chart.height)

          this.chart = new Chart(this.$refs.chart, {
            type: this.type,
            options: {
              onHover: (_, item) => {
                this.$emit('hover', item)
              },
              legend: {
                display: false
              },
              ...this.chartOptions
            },
            data: this.chartData
          })
        }
      })
    }
  },
  reload () {
    this.chart.destroy()
    this.createChart()
  },
  created () {
    this.createChart()
  }
}
</script>

<style lang="scss" scoped>
.chart-container {
  position:relative;
  margin: 0 auto;
}

.chart {
  padding: 0;
  margin: auto;
  display: block;
  width: 120px;
  height: 120px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

canvas {
  width: 100% !important;
  height: 100% !important;
}
</style>
