<template>
  <app-layout>
    <div class="px-4 p-sm-5 container-fluid">
      <div class="heading d-flex justify-content-between mb-5">
        <h2>Tableau de bord</h2>
      </div>

      <div class="row">
        <div class="col-xl-3 col-md-6 mb-4">
          <card
            title="Total produits"
            :value="dashboard.data.products"
            icon="cubes"
            variant="info"
            :to="{ name: 'products.index' }"
          />
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
          <card
            title="Total Boutiques"
            :value="dashboard.data.stores"
            icon="store"
            variant="primary"
            :to="{ name: 'stores.index' }"
          />
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
          <card
            title="Nombre total des commandes"
            :value="dashboard.data.orders"
            icon="receipt"
            variant="primary"
            :to="{ name: 'orders.index' }"
          />
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
          <card
            title="Nombre total des factures"
            :value="dashboard.data.invoices"
            icon="file-invoice-dollar"
            variant="success"
            :to="{ name: 'invoices.index' }"
          />
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
          <card
            title="Nombre total des utilisateurs"
            :value="dashboard.data.users"
            icon="users"
            variant="info"
            :to="{ name: 'users.index' }"
          />
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
          <card
            title="Demandes ouvertes"
            :value="dashboard.data.supportRequests"
            icon="headset"
            variant="warning"
            :to="handleSupportRequestOpen"
          />
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
          <card
            title="Demandes de retours"
            :value="dashboard.data.returnRequests"
            icon="exclamation"
            variant="danger"
            :to="{ name: 'returns.index' }"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-xl-8 col-lg-7">
          <div class="card shadow mb-4">
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 class="m-0 font-weight-bold text-primary">Factures</h6>
            </div>
            <div class="card-body text-center">
              <div>
                <span class="btn btn-sm m-2" :class="{ 'btn-outline-info' : dashboard.activeFilterInvoice !== 'years', 'btn-info' : dashboard.activeFilterInvoice === 'years'}" @click="fetchGraphInvoices('years')">Années</span>
                <span class="btn btn-sm m-2" :class="{ 'btn-outline-info' : dashboard.activeFilterInvoice !== 'months', 'btn-info' : dashboard.activeFilterInvoice === 'months'}" @click="fetchGraphInvoices('months')">Mois</span>
                <span class="btn btn-sm m-2" :class="{ 'btn-outline-info' : dashboard.activeFilterInvoice !== 'days', 'btn-info' : dashboard.activeFilterInvoice === 'days'}" @click="fetchGraphInvoices('days')">Jours</span>
                <span class="btn btn-sm m-2" :class="{ 'btn-outline-info' : dashboard.activeFilterInvoice !== 'hours', 'btn-info' : dashboard.activeFilterInvoice === 'hours'}" @click="fetchGraphInvoices('hours')">Heures</span>
              </div>
              <chart
                :isLoading="dashboard.charts.invoices.isLoading"
                loader-size="21"
                type="line"
                :chart-data="dashboard.charts.invoices.data"
                :chart-options="chartAnimation"
                chart-width="100%"
                chart-height="340px"
              />
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-5">
          <div class="card shadow mb-4">
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 class="m-0 font-weight-bold text-primary">Utilisateurs</h6>
            </div>
            <div class="card-body text-center">
              <div class="mt-4 text-center small">
                <chart
                  :isLoading="dashboard.charts.users.isLoading"
                  loaderSize="15"
                  :chart-data="dashboard.charts.users.data"
                  :chart-options="chartAnimation"
                  chart-width="240px"
                  chart-height="240px"
                  @hover="selectMerchantOrUser"
                />
                <div class="pt-5">
                  <span class="mr-2" :class="{ 'customer-active': (dashboard.selectedMerchantOrUser === 0)}">
                    <fa-icon :icon="['fas', 'user']" size="2x" :class="(dashboard.selectedMerchantOrUser === 0) ? 'customer-color' : 'color-grey'"></fa-icon>
                    <span class="label-chart">Clients</span>
                  </span>
                  <span class="mr-2" :class="{ 'merchant-active': (dashboard.selectedMerchantOrUser === 1)}">
                    <fa-icon :icon="['fas', 'user-tag']" size="2x" :class="(dashboard.selectedMerchantOrUser === 1) ? 'merchant-color' : 'color-grey'"></fa-icon>
                    <span class="label-chart">Vendeurs</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-8 col-lg-7">
          <div class="card shadow mb-4">
              <div class="card-header py-3">
                  <h6 class="m-0 font-weight-bold text-primary">Commandes</h6>
              </div>
              <div class="card-body text-center">
                <div>
                  <span class="btn btn-sm m-2" :class="{ 'btn-outline-info' : dashboard.activeFilterOrder !== 'years', 'btn-info' : dashboard.activeFilterOrder === 'years' }" @click="fetchGraphOrders('years')">Années</span>
                  <span class="btn btn-sm m-2" :class="{ 'btn-outline-info' : dashboard.activeFilterOrder !== 'months', 'btn-info' : dashboard.activeFilterOrder === 'months' }" @click="fetchGraphOrders('months')">Mois</span>
                  <span class="btn btn-sm m-2" :class="{ 'btn-outline-info' : dashboard.activeFilterOrder !== 'days', 'btn-info' : dashboard.activeFilterOrder === 'days' }" @click="fetchGraphOrders('days')">Jours</span>
                  <span class="btn btn-sm m-2" :class="{ 'btn-outline-info' : dashboard.activeFilterOrder !== 'hours', 'btn-info' : dashboard.activeFilterOrder === 'hours' }" @click="fetchGraphOrders('hours')">Heures</span>
                </div>
                <chart
                :isLoading="dashboard.charts.orders.isLoading"
                size="14"
                type="line"
                :chart-data="dashboard.charts.orders.data"
                :chart-options="chartAnimation"
                chart-width="100%"
                chart-height="340px"
              />
              </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-5">
          <div class="card shadow mb-4">
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 class="m-0 font-weight-bold text-primary">Worker</h6>
            </div>
            <div class="card-body text-center">
              <div class="mt-4 text-center small">
                <chart
                  :isLoading="dashboard.charts.worker.isLoading"
                  loaderSize="15"
                  :chart-data="dashboard.charts.worker.data"
                  :chart-options="chartAnimation"
                  chart-width="240px"
                  chart-height="240px"
                  @hover="selectWorkerJob"
                />
                <div class="pt-5">
                  <span class="mr-2" :class="{ 'worker-failed': (dashboard.selectedWorkerJob === 1)}">
                    <fa-icon :icon="['fas', 'times']" size="2x" :class="(dashboard.selectedWorkerJob === 1) ? 'worker-failed' : 'color-grey'"></fa-icon>
                    <span class="label-chart">{{ dashboard.data.worker.failed }}</span>
                  </span>
                  <span class="mr-2" :class="{ 'worker-actived': (dashboard.selectedWorkerJob === 0)}">
                    <fa-icon :icon="['fas', 'question']" size="2x" :class="(dashboard.selectedWorkerJob=== 0) ? 'worker-actived' : 'color-grey'"></fa-icon>
                    <span class="label-chart">{{ dashboard.data.worker.actived }}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-3 col-md-6 mb-4">
          <card
            title="Total FAQ"
            :value="dashboard.data.faqCategories"
            icon="question"
            variant="success"
            :to="{ name: 'faq-categories.index' }"
          />
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
          <card
            title="Total Pages"
            :value="dashboard.data.pages"
            icon="file"
            variant="warning"
            :to="{ name: 'pages.index' }"
          />
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
          <card
            title="Total Categories"
            :value="dashboard.data.productCategories"
            icon="tag"
            variant="info"
            :to="{ name: 'product-categories.index' }"
          />
        </div>
      </div>
    </div>
  </app-layout>
</template>

<script>
import AppLayout from '@/layouts/AppLayout'
import Card from '@/components/dashboard/Card'
import Chart from '@/components/dashboard/Chart'

export default {
  components: {
    AppLayout,
    Card,
    Chart
  },
  computed: {
    dashboard () {
      return this.$store.state.dashboard
    },
    chartAnimation () {
      return this.dashboard.isFirstLoad ? {} : {
        animation: {
          duration: 0
        }
      }
    }
  },
  methods: {
    selectMerchantOrUser (item) {
      if (!item || !item[0]) {
        this.$store.commit('dashboard/SELECT_MERCHANT_USER', null)
        return
      }

      this.$store.commit('dashboard/SELECT_MERCHANT_USER', item[0].index)
    },
    selectWorkerJob (item) {
      if (!item || !item[0]) {
        this.$store.commit('dashboard/SELECT_WORKER_JOB', null)
        return
      }

      this.$store.commit('dashboard/SELECT_WORKER_JOB', item[0].index)
    },
    handleSupportRequestOpen () {
      this.$store.commit('supportRequests/SET_TEXT_FILTER', '')
      this.$store.commit('supportRequests/SET_SOURCE_FILTER', '')
      this.$store.commit('supportRequests/SET_ACTIVE_FILTER', 'newRequests')
      this.$store.commit('supportRequests/SET_STATUS_FILTER', 'open')
      this.$store.commit('supportRequests/SET_ASSIGN_FILTER', null)
      this.$store.commit('supportRequests/SET_WAITING_REPLY_FILTER', '')

      this.$router.push({ name: 'supportRequests.index' })
    },
    fetchGraphOrders (filter) {
      this.$store.dispatch('dashboard/fetchGraphOrders', filter)
    },
    fetchGraphInvoices (filter) {
      this.$store.dispatch('dashboard/fetchGraphInvoices', filter)
    }
  },
  async created () {
    await this.$store.dispatch('dashboard/fetchAll')
  }
}
</script>
